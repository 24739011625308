import {
  SYSTEM_USER_ID,
  SYSTEM_USER_TYPE,
  SYSTEM_JWT_TOKEN,
  SYSTEM_PERMISSION_KEY,
  SYSTEM_USER_FULL_NAME,
  SYSTEM_PREFERRED_NAME,
  SYSTEM_REF_URL,
  CLIENT_JWT_TOKEN,
  CLIENT_USER_FULL_NAME,
  CLIENT_ENFORCE_UPDATE,
  CLIENT_USER_TYPE,
  CLIENT_CLIENT_TYPE,
  CLIENT_USER_ID,
  CLIENT_USER_EMAIL,
  CLIENT_SELECTED_ASSOCIATION_ID,
  CLIENT_SELECTED_ASSOCIATION_TYPE,
  CLIENT_PREFERRED_NAME,
  CLIENT_ACCESS_CODE,
  CLIENT_TEMP_PASSWORD,
  CLIENT_PERMISSION_KEY,
  ADMIN_USER_TYPE,
  ADMIN_USER_ID,
  ADMIN_USER_FULL_NAME,
  ADMIN_PERMISSION_KEY,
  ADMIN_JWT_TOKEN,
  ADMIN_PREFERRED_NAME,
  ADMIN_REF_URL,
  ADMIN_ACCESS_CODE,
  ADMIN_TEMP_PASSWORD,
  ADMIN_UNIQUE_ID,
  ADMIN_MAIN_PROFILE_USER_ID,
  ADMIN_MAIN_PROFILE_FULL_NAME,
  ADMIN_USER_PATHS,
  ADMIN_SUPERVISOR_PROFILE_FULL_NAME,
  ADMIN_SUPERVISOR_PROFILE_USER_ID,
  CLIENT_IS_PRIMARY_ACCOUNT,
  CLIENT_PROFILE,
  ADMIN_ENFORCE_PASSWORD,
  CLIENT_IS_SPOUSE,
  SYSTEM_ENCRYPTION_KEY,
  ADMIN_ENCRYPTION_KEY,
  CLIENT_ENCRYPTION_KEY,
  CLIENT_USER_SPOUSE_ID,
  CLIENT_USER_PATHS,
  ADMIN_COUNTRY_CODE,
  CLIENT_COUNTRY_CODE,
  ADMIN_USER_EMAIL,
  CLIENT_SWITCHED_ID,
  ADMIN_SUPPORT_DATA,
  ADMIN_SEARCH_URL,
} from '../constants/appConfig';
import { setLocalStorage, clearLocalStorage, setLocalStorageBulk } from './storageUtil';

export const systemLoginLocalStorage = responseData => {
  setLocalStorageBulk([
    { key: SYSTEM_PERMISSION_KEY, value: responseData.permissionModels },
    { key: SYSTEM_USER_FULL_NAME, value: responseData.fullName },
    { key: SYSTEM_PREFERRED_NAME, value: responseData.preferredName },
    { key: SYSTEM_USER_ID, value: responseData.userId },
    { key: SYSTEM_REF_URL, value: responseData.refUrl },
    { key: SYSTEM_USER_TYPE, value: 'SYSTEM' },
    { key: SYSTEM_ENCRYPTION_KEY, value: responseData?.encryptionKey },
  ]);

  setLocalStorage(SYSTEM_JWT_TOKEN, responseData.token);

  localStorage.setItem('login-event-system', 'login' + Math.random());
  return responseData;
};

export const systemLogoutLocalStorage = () => {
  clearLocalStorage(SYSTEM_JWT_TOKEN);
  clearLocalStorage(SYSTEM_PERMISSION_KEY);
  clearLocalStorage(SYSTEM_USER_FULL_NAME);
  clearLocalStorage(SYSTEM_PREFERRED_NAME);
  clearLocalStorage(SYSTEM_USER_ID);
  clearLocalStorage(SYSTEM_REF_URL);
  clearLocalStorage(SYSTEM_USER_TYPE);
  clearLocalStorage(SYSTEM_ENCRYPTION_KEY);

  localStorage.setItem('logout-event-system', 'logout' + Math.random());
  return;
};

export const clientLoginLocalStorage = responseData => {
  setLocalStorageBulk([
    { key: CLIENT_PROFILE, value: responseData },
    { key: CLIENT_USER_FULL_NAME, value: responseData.fullName },
    { key: CLIENT_ENFORCE_UPDATE, value: responseData.enforceUpdate },
    { key: CLIENT_USER_TYPE, value: 'CLIENT' },
    { key: CLIENT_CLIENT_TYPE, value: responseData.clientType },
    { key: CLIENT_USER_ID, value: responseData.id },
    { key: CLIENT_USER_EMAIL, value: responseData.email },
    { key: CLIENT_SELECTED_ASSOCIATION_ID, value: responseData?.id },
    { key: CLIENT_SELECTED_ASSOCIATION_TYPE, value: responseData.clientType },
    { key: CLIENT_PREFERRED_NAME, value: responseData.preferredName },
    { key: CLIENT_ACCESS_CODE, value: responseData?.accessCode },
    { key: CLIENT_IS_PRIMARY_ACCOUNT, value: responseData?.primaryAccount },
    { key: CLIENT_IS_SPOUSE, value: responseData?.isSpouse },
    { key: CLIENT_ENCRYPTION_KEY, value: responseData?.encryptionKey },
    { key: CLIENT_USER_SPOUSE_ID, value: responseData?.spouseId },
    { key: CLIENT_COUNTRY_CODE, value: responseData?.countryCode },
    {
      key: CLIENT_SWITCHED_ID,
      value: responseData?.isSpouse ? responseData?.spouseId : responseData?.id,
    },
  ]);

  setLocalStorage(CLIENT_JWT_TOKEN, responseData.token);
  clearLocalStorage(CLIENT_TEMP_PASSWORD);

  localStorage.setItem('login-event-client', 'login' + Math.random());
  return responseData;
};

export const clientLogoutLocalStorage = () => {
  clearLocalStorage(CLIENT_JWT_TOKEN);
  clearLocalStorage(CLIENT_PERMISSION_KEY);
  clearLocalStorage(CLIENT_USER_FULL_NAME);
  clearLocalStorage(CLIENT_ENFORCE_UPDATE);
  clearLocalStorage(CLIENT_USER_ID);
  clearLocalStorage(CLIENT_USER_TYPE);
  clearLocalStorage(CLIENT_USER_EMAIL);
  clearLocalStorage(CLIENT_SELECTED_ASSOCIATION_ID);
  clearLocalStorage(CLIENT_SELECTED_ASSOCIATION_TYPE);
  clearLocalStorage(CLIENT_PREFERRED_NAME);
  clearLocalStorage(CLIENT_IS_PRIMARY_ACCOUNT);
  clearLocalStorage(CLIENT_IS_SPOUSE);
  clearLocalStorage(CLIENT_ENCRYPTION_KEY);
  clearLocalStorage(CLIENT_USER_SPOUSE_ID);
  clearLocalStorage(CLIENT_USER_PATHS);
  clearLocalStorage(CLIENT_COUNTRY_CODE);
  clearLocalStorage(CLIENT_SWITCHED_ID);
  localStorage.setItem('logout-event-client', 'logout' + Math.random());
  return;
};

export const adminLoginLocalStorage = responseData => {
  setLocalStorageBulk([
    { key: ADMIN_USER_TYPE, value: 'ADMIN' },
    { key: ADMIN_PERMISSION_KEY, value: responseData.permissionModels },
    { key: ADMIN_USER_FULL_NAME, value: responseData.fullName },
    { key: ADMIN_PREFERRED_NAME, value: responseData.preferredName },
    { key: ADMIN_USER_ID, value: responseData.userId },
    { key: ADMIN_REF_URL, value: responseData.refUrl },
    { key: ADMIN_ACCESS_CODE, value: responseData?.accessCode },
    { key: ADMIN_UNIQUE_ID, value: responseData?.id },
    { key: ADMIN_ENCRYPTION_KEY, value: responseData?.encryptionKey },
    { key: ADMIN_COUNTRY_CODE, value: responseData?.countryCode },
    { key: ADMIN_USER_EMAIL, value: responseData?.email },
    {
      key: ADMIN_SUPPORT_DATA,
      value: {
        supportOrganizationId: responseData.supportOrganizationId,
        loginUserSupportId: responseData.loginUserSupportId,
        defaultUserSupportId: responseData.defaultUserSupportId,
      },
    },
  ]);

  setLocalStorage(ADMIN_JWT_TOKEN, responseData.token);
  clearLocalStorage(ADMIN_TEMP_PASSWORD);
  localStorage.setItem('login-event-admin', 'login' + Math.random());
};

export const adminLogoutLocalStorage = () => {
  clearLocalStorage(ADMIN_JWT_TOKEN);
  clearLocalStorage(ADMIN_PERMISSION_KEY);
  clearLocalStorage(ADMIN_USER_FULL_NAME);
  clearLocalStorage(ADMIN_USER_ID);
  clearLocalStorage(ADMIN_USER_TYPE);
  clearLocalStorage(ADMIN_SUPPORT_DATA);
  clearLocalStorage(ADMIN_PREFERRED_NAME);
  clearLocalStorage(ADMIN_REF_URL);
  clearLocalStorage(ADMIN_UNIQUE_ID);

  clearLocalStorage(ADMIN_MAIN_PROFILE_FULL_NAME);
  clearLocalStorage(ADMIN_MAIN_PROFILE_USER_ID);
  clearLocalStorage(ADMIN_USER_PATHS);
  clearLocalStorage(ADMIN_SUPERVISOR_PROFILE_FULL_NAME);
  clearLocalStorage(ADMIN_SUPERVISOR_PROFILE_USER_ID);
  clearLocalStorage(ADMIN_ENFORCE_PASSWORD);
  clearLocalStorage(ADMIN_ENCRYPTION_KEY);
  clearLocalStorage(ADMIN_COUNTRY_CODE);
  clearLocalStorage(ADMIN_USER_EMAIL);
  clearLocalStorage(ADMIN_SEARCH_URL);

  localStorage.setItem('logout-event-admin', 'logout' + Math.random());
};
